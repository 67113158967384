import React, { useState, useEffect, useRef  } from 'react';
import { Toast } from 'primereact/toast';
import ResultProcessosBuscadosService from '../../app/service/ResultProcessosBuscados';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonEtrium } from '../ButtonEtrium';
import { format } from 'date-fns';
import ProcessoAutomaticoService from '../../app/service/ProcessoAutomaticoService';

const TabelaProcessos = ({ userId }) => {
  const [processos, setProcessos] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const service = new ResultProcessosBuscadosService();
  const serviceProcessoAuto = new ProcessoAutomaticoService();
  const toastRef = useRef(null)
  const [showAlert, setShowAlert] = useState(false);
  
  useEffect(() => {
    const fetchProcessos = async () => {
      try {
        const response = await service.getEndpoint(userId);
        setProcessos(response.data); // Preenche a tabela com os dados iniciais
      } catch (error) {
        console.error('Erro ao buscar processos:', error);
      }
    };

    fetchProcessos();
  }, [userId]);

  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'dd/MM/yyyy');
    } catch (error) {
      return 'Data inválida';
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div
          className="row"
          style={{
            display: 'flex',
            color: '#000',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              flex: 1,
              padding: '0 10px',
              margin: '8px 0',
              borderRight: '1px solid #dcdcdc',
            }}
          >
            <p style={{ color: '#000', margin: '4px 0',  fontSize: '15px' }}>
              <strong>Polo Ativo:</strong> {data.poloAtivo}
            </p>
            <p style={{ color: '#000', margin: '4px 0',  fontSize: '15px' }}>
              <strong>Polo Passivo:</strong> {data.poloPassivo}
            </p>
            <p style={{ color: '#000', margin: '4px 0',  fontSize: '15px' }}>
              <strong>Outras Partes:</strong> {data.otherParts}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              padding: '0 10px',
              margin: '8px 0',
            }}
          >
            <p style={{ color: '#000', margin: '4px 0',  fontSize: '15px' }}>
              <strong>Data de Distribuição:</strong> {formatDate(data.distributionDate)}
            </p>
            <p style={{ color: '#000', margin: '4px 0',  fontSize: '15px' }}>
              <strong>Eventos:</strong> {data.events ? `${formatDate(data.events.split(' - ')[0])} - ${data.events.split(' - ')[1]}` : 'Sem eventos'}</p>
          </div>
        </div>
      </>
    );
  };
  

  const renderImportButton = (rowData) => {
    return (
      <ButtonEtrium
        buttonType="primary"
        children="Importar"
        onClick={() => handleImport(rowData)}
      />
    );
  };

  const handleImport = async (rowData) => {
    serviceProcessoAuto.importar(userId, rowData.cnj, rowData.instance)
    .then((importacao) => {
      toastRef.current.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: `Processo ${rowData.cnj} da instância ${rowData.instance || 'N/A'} importado com sucesso!`,
        life: 4000
      });
    })
    .catch((error) => {
      toastRef.current.show({
        severity: 'error',
        summary: 'Erro',
        detail: `Falha ao importar o processo ${rowData.cnj} da instância ${rowData.instance || 'N/A'}. Erro: ${error.message}`,
        life: 4000
      });
    });
  
  };

  return (
    <div className="tabela-processos">
      <Toast ref={toastRef} />
      <DataTable
        value={processos}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="cnj"
        paginator
        rows={10}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
        emptyMessage="Nenhum processo encontrado"
      >
        <Column field="cnj" header="CNJ" />
        <Column field="status" header="Status" />
        <Column field="system" header="Sistema" />
        <Column field="instance" header="Instância" />
        <Column
          body={renderImportButton}
          style={{ width: '8em', textAlign: 'center' }}
          header="Ações"
        />
        <Column expander style={{ width: '3em' }} />
      </DataTable>
    </div>
  );
};

export default TabelaProcessos;
