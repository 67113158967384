import React, { useEffect, useState } from "react";
import EmpresaService from "../../app/service/EmpresaService";
import Navbar from "../../componentes/Navbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { withRouter } from "react-router-dom";
import { Loading } from "../../componentes/Loading";
import {formatDateHoraTela} from "../../utils/DateFormat";

function PainelEmpresa(props) {
  const [empresas, setEmpresas] = useState([]);
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [consultaNome, setConsultaNome] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const empresaService = new EmpresaService();

  // Função para buscar empresas
  function findEmpresas(dateini, dateFim) {
    empresaService.consultarEmpresasDetalhadas(dateini, dateFim).then((res) => {
      const empresasComCalculado = res.data.content.map((empresa) => ({
        ...empresa,
        usuariosPorPlano: `${empresa.numeroUsuarios}/${empresa.empresa.qtdeUsuario}`,
        processosPlano: `${empresa.numeroProcessos}/${empresa.empresa.qtdeProcessoPush}`
      }));
      setEmpresas(empresasComCalculado);
    });
  }

  // Função para buscar todas as empresas
  const findALL = () => {
    let dateini = new Date();
    let dateFim = new Date();
    dateini.setDate(1);
    dateFim.setMonth(dateini.getMonth() + 1, 1);
    setDataInicial(dateini);
    setDataFinal(dateFim);
    findEmpresas(dateini, dateFim);
  };

  useEffect(() => {
    setLoading(true);
    findALL();
    setLoading(false); 
  }, []);

  // Função para filtrar empresas com base no nome
  const empresasFiltradas = empresas.filter((empresa) =>
    empresa.empresa.nome.toLowerCase().includes(consultaNome.toLowerCase())
  );

  const onRowClick = (rowData) => {
    props.history.push(`/empresa/detalhes/${rowData.empresa.codigo}`);
  };

  const bodyEmpresaPlano = (rowData)=>{
    const {empresa} = rowData
    if(empresa.status === 0 ){
      return <div>
       Interessada
      </div>
    }
    if(empresa.status === 1 ){
      return <div>
        E-mail não confirmado
      </div>
    }
    const nome = empresa.plano?.tipoPlano?.nome || "Sem Plano"
    return(<div>
      {nome}
    </div>)
  }
  const bodyDate = (rowData)=>{
    const {empresa} = rowData
    const data = formatDateHoraTela(empresa.dataCadastro)
    return(<div>
      {data}
    </div>)
  }

  // Função para renderizar a tabela de empresas com paginação
  const renderPagination = () => {
    return (
      <div className="pagination-container">
        <DataTable
          value={empresasFiltradas}
          paginator
          rows={itemsPerPage}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
          emptyMessage="Nenhuma empresa encontrada"
          selectionMode="multiple"
          onRowClick={(e) => onRowClick(e.data)} 
        >
          <Column field="empresa.nome" header="Nome" />
          <Column field="empresa.cpfCnpj" header="CNPJ" />
          <Column body={bodyEmpresaPlano} header="Plano" />
          <Column field="usuariosPorPlano" header="Usuários / Plano" />
          <Column field="processosPlano" header="Processos" />
          <Column body={bodyDate} header="Data de criação" />
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <Loading open={loading} />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="pi pi-building"></i>
                      <span> Painel de Empresas</span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 d-flex justify-content-start mb-3">
                        <div className="form-inline">
                          <label className="mr-2">Itens por página:</label>
                          <select
                            className="form-control"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(Number(e.target.value))}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6 d-flex justify-content-end mb-3">
                        <div className="input-group" style={{ width: "300px", height: "35px" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar empresa"
                            value={consultaNome}
                            onChange={(e) => setConsultaNome(e.target.value)}
                          />
                          <span className="input-group-prepend pointer">
                            <button type={"submit"} className="input-group-text">
                              <i className="fas fa-search"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        {renderPagination()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withRouter(PainelEmpresa);
