import ApiService from '../apiservice'

class EmpresaService extends ApiService {

  constructor() {
    super('/empresas')
  }

  cadastrarEmpresa(empresa) {
    return this.post('', empresa);
  }

  editarEmpresa(empresa){
    return this.put('/alterar-empresa',empresa)
  }

  editarRecursoEmpresa(empresa){
    return this.put('/alterar-recursos-empresa',empresa)
  }

  consultarEmpresa(codigo){
    return this.get(`/${codigo}`)
  }

  consultarEmpresas(){
    return this.get(``)
  }

  consultarEmpresasDetalhadas(){
    return this.get(`/detalhadas`)
  }

  cadastrarNovaEmpresa(empresa) {
    return this.post('/cadastrar', empresa);
  }
  cadastrarNovaEmpresaInteressada(cnpj) {
    return this.post(`/cadastrar/interessada?cnpj=${cnpj}`);
  }
}


export default EmpresaService;
